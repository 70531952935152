import React from "react";
import Layout from "components/Layout";
import ExperienceEnte from "components/homepageSections/ExperienceEnte";
import HeroSection from "components/homepageSections/HeroSection";
import { PageProps, graphql } from "gatsby";
import { FluidImageData } from "types";
import "styles/landing.css";
import Backups from "components/authHomepageSections/Backups";
import Platform from "components/authHomepageSections/Platform";
import { authIcons } from "images/icons/auth-homepage-icons";
import DownloadSection from "components/authHomepageSections/DownloadSection";
import Features1 from "components/authHomepageSections/Features1";
import Features2 from "components/authHomepageSections/Features2";
import OpenSource from "components/authHomepageSections/OpenSource";
import previewImage from "images/main/preview-auth.png";
import { PrimaryDownloadCTAText } from "constants/download";

interface IPageData {
    authHomePage: FluidImageData;
    connector1: FluidImageData;
    connector2: FluidImageData;
    authIpadHome: FluidImageData;
    authLaptopHome: FluidImageData;
    authOfflineFeature: FluidImageData;
    authHomeScreenBlank: FluidImageData;
    authCategory: FluidImageData;
    searchBar: FluidImageData;
    authQRPhone: FluidImageData;
    searchBar2: FluidImageData;
    authApps: FluidImageData;
}

interface HeroSectionProps {
    headingText: string;
    headingHighlightText: string;
    paragraphText: string;
    buttonText: string;
    backdropBackground: string;
    duckyShieldColor: string;
}

interface ExperienceEnteProps {
    background: string;
    heading: string;
    subheading: string;
    icon: string;
}

const Index: React.FC<PageProps<IPageData>> = ({
    path,
    data: {
        authHomePage,
        connector1,
        connector2,
        authIpadHome,
        authLaptopHome,
        authOfflineFeature,
        authHomeScreenBlank,
        authCategory,
        searchBar,
        authQRPhone,
        searchBar2,
        authApps,
    },
}) => {
    const heroSectionProps: HeroSectionProps = {
        headingText: "",
        headingHighlightText: "Ente Auth",
        paragraphText:
            "Open source 2FA authenticator, with end-to-end encrypted backups",
        buttonText: PrimaryDownloadCTAText.AUTH,
        backdropBackground: `linear-gradient(315deg, #531dab, #8330E3)`,
        duckyShieldColor: "#a117e6",
    };

    const experienceEnteProps: ExperienceEnteProps = {
        background: "linear-gradient(315deg, #531dab, #782bd6)",
        heading: "Protect your accounts",
        subheading: "Scan the QR code to get started",
        icon: "auth",
    };

    return (
        <Layout
            {...{
                title: "Ente Auth - Open source 2FA authenticator, with E2EE backups",
                description:
                    "Protect your accounts with Ente Auth - Free, open source, cross-platform 2FA authenticator, with end-to-end encrypted backups",
                path,
                shouldShowDownloads: false,
                isPageAuth: true,
                image: { path: previewImage },
            }}
        >
            <HeroSection images={[authHomePage]} {...heroSectionProps} />
            <Backups
                images={[connector1, connector2, authHomePage, authIpadHome]}
                sectionIcon={authIcons["backup"]}
            />
            <Platform
                images={[authLaptopHome, authHomePage]}
                sectionIcon={authIcons["crossPlatform"]}
            />
            <Features1
                images={[
                    authOfflineFeature,
                    searchBar,
                    authHomeScreenBlank,
                    authCategory,
                ]}
                sectionIcon={authIcons["thunder"]}
            />
            <DownloadSection sectionIcon={authIcons["download"]} />
            <Features2
                images={[authQRPhone, searchBar2, authApps]}
                sectionIcon={authIcons["import"]}
            />
            <ExperienceEnte {...experienceEnteProps} />
            <OpenSource sectionIcon={authIcons["openSource"]} />
        </Layout>
    );
};

export default Index;

export const pageQuery = graphql`
    query HeaderImageQuery {
        authHomePage: imageSharp(
            fluid: { originalName: { eq: "auth-home-screen.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        connector1: imageSharp(
            fluid: { originalName: { eq: "auth-backups-connector1.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        connector2: imageSharp(
            fluid: { originalName: { eq: "auth-backups-connector2.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authIpadHome: imageSharp(
            fluid: { originalName: { eq: "auth-ipad-home.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authLaptopHome: imageSharp(
            fluid: { originalName: { eq: "auth-laptop-home.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authOfflineFeature: imageSharp(
            fluid: { originalName: { eq: "auth-offline-feature.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authHomeScreenBlank: imageSharp(
            fluid: { originalName: { eq: "auth-home-screen-blank.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authCategory: imageSharp(
            fluid: { originalName: { eq: "auth-category.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        searchBar: imageSharp(
            fluid: { originalName: { eq: "search-bar.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authQRPhone: imageSharp(
            fluid: { originalName: { eq: "auth-phone-qr.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        searchBar2: imageSharp(
            fluid: { originalName: { eq: "search-bar2.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
        authApps: imageSharp(
            fluid: { originalName: { eq: "auth-2FA-apps.png" } }
        ) {
            fluid(quality: 70) {
                srcSetWebp
                aspectRatio
            }
        }
    }
`;
